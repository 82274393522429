import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  input,
  OnDestroy,
  output,
  viewChild
} from '@angular/core';
import { MarketingType, Pagination } from '@ui/shared/models';
import { LoadingSpinnerComponent, PropertyCardComponent } from '@ui/legacy-lib';
import { HomepagePropertySearchResponse } from '../../../../models';

@Component({
  selector: 'app-properties-card-list',
  templateUrl: './properties-card-list.component.html',
  styleUrls: ['./properties-card-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [PropertyCardComponent, LoadingSpinnerComponent]
})
export class PropertiesCardListComponent implements OnDestroy, AfterViewInit {
  readonly properties = input<
    HomepagePropertySearchResponse[],
    HomepagePropertySearchResponse[]
  >(undefined, {
    transform: value =>
      value.map(property => ({
        marketingType: MarketingType.RENT, // added to show price on cards
        type: property.propertyType,
        housingPermissionNeeded: property.wbs,
        attachments: property.titleImage ? [property.titleImage] : [],
        rooms: property.totalRooms,
        ...property
      }))
  });
  readonly pagination = input<Pagination>(undefined);
  readonly isLoading = input<boolean>(undefined);
  readonly intersect = output<number>();
  readonly anchor = viewChild<ElementRef>('anchor');
  private observer: IntersectionObserver;

  ngAfterViewInit(): void {
    // observer watches for intersection (50%) with invisible element with 5rem height
    // on intersect (if hasNext) and emit pageNum then load new cards
    this.observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting === true) {
          const pagination = this.pagination();
          if (pagination.hasNext) {
            this.intersect.emit(pagination.next);
          }
        }
      },
      {
        threshold: 0.5
      }
    );
    this.observer.observe(this.anchor().nativeElement as HTMLElement);
  }

  ngOnDestroy(): void {
    this.observer.disconnect();
  }

  public redirectToApp(applicationLink: string): void {
    window.open(applicationLink, '_blank');
  }
}
