import { inject, NgModule } from '@angular/core';
import { environment } from '../../environments/environment';
import { ENVIRONMENT_CONFIG } from './environment';

const providers = [{ provide: ENVIRONMENT_CONFIG, useValue: environment }];

@NgModule({
  providers
})
export class CoreModule {
  constructor() {
    const parentModule = inject(CoreModule, { optional: true, skipSelf: true });

    if (parentModule) {
      throw new Error(
        `${String(
          parentModule
        )} has already been loaded. Import core module in the AppModule only.`
      );
    }
  }
}
