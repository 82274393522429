import { gql } from 'apollo-angular';

import { Pagination } from '@ui/shared/models';
import { paginationQuery } from '@ui/legacy-lib';
import {
  HomepageOptionsResponse,
  HomepagePropertySearchResponse
} from '../models';

export interface HomepagePropertySearch {
  propertyList: {
    nodes: HomepagePropertySearchResponse[];
    page: Pagination;
  };
}

export interface HomepageOptions {
  getOptions: HomepageOptionsResponse;
}

const propertySearchAvailableFrom = `
  dateAvailable
  stringAvailable
`;

const propertySearchTitleImage = `
  title
  name
  identifier
  extension
  url
`;

const propertySearchAddress = `
  city
  country
  coordinates {
    lon
    lat
  }
  region
  street
  houseNumber
  zipCode
  district
  additional
`;

const homepagePropertySearchResponse = `
  page {
    ${paginationQuery}
  }
  nodes {
    name
    totalRooms
    availableFrom {
      ${propertySearchAvailableFrom}
    }
    titleImage {
      ${propertySearchTitleImage}
    }
    address {
      ${propertySearchAddress}
    }
    showAddress
    size
    totalRentGross
    bathRooms
    propertyType
    externalId
    wbs
    applicationLink
    floor
    status
  }
`;

export const homepagePropertySearchQuery = gql`
  query propertyList($input: HomepagePropertySearchRequest!) {
    propertyList(input: $input) {
      ${homepagePropertySearchResponse}
    }
  }
`;

const homepageOptionsResponse = `
  type
  branding {
    theme {
      name
      primaryColor
      secondaryColor
      primaryTextColor
      secondaryTextColor
      buttonTextColor
      backgroundColor
      cardBackgroundColor
      active
    }
  }
  homepageModuleSVGs {
    svgAsXmlString
  }
`;

export const homepageOptionsQuery = gql`
  query getOptions($input: String) {
    getOptions(input: $input) {
      ${homepageOptionsResponse}
    }
  }
`;
