import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
  input,
  viewChild
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { MapComponent, MapRadiusDrawInfo } from '@ui/legacy-lib';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AsyncPipe } from '@angular/common';
import { HomepagePropertySearchResponse } from '../../../../models';
import {
  getMapRadiusDrawInfo,
  getTileServerUrl
} from '../../../../+state/property-list';

@UntilDestroy()
@Component({
  selector: 'app-properties-map',
  templateUrl: './properties-map.component.html',
  styleUrls: ['./properties-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MapComponent, AsyncPipe]
})
export class PropertiesMapComponent implements OnInit {
  readonly map = viewChild(MapComponent);
  readonly properties = input<HomepagePropertySearchResponse[]>(undefined);
  public tileServerUrl$: Observable<string>;
  public mapRadiusDrawInfo: MapRadiusDrawInfo;
  private store = inject(Store);

  ngOnInit(): void {
    this.store
      .select(getMapRadiusDrawInfo)
      .pipe(untilDestroyed(this))
      .subscribe(drawInfo => {
        this.mapRadiusDrawInfo = drawInfo;
      });

    this.tileServerUrl$ = this.store.select(getTileServerUrl);
  }

  public openApplyPage(applicationLink: string) {
    window.open(applicationLink, '_blank');
  }
}
